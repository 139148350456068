































































import Vue from 'vue'
import StepList from './StepList.vue'
import Direction from './Direction.vue'
import Card from '@/components/Card.vue'
import Input from './Input.vue'
import Switcher from '@/components/Switcher.vue'
import Button from '@/components/BaseComponents/BaseButton/BaseButton.vue'
import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
export default Vue.extend({
  name: 'Tracking',
  components: {
    StepList,
    Card,
    Direction,
    Input,
    Switcher,
    Button,
    VSelect
  },
  data () {
    return {
      URLlink: '',
      parametr: '',
      platform: '',
      options: ['Dynamic parameter', 'Static parameter'],
      platforms: ['AutomatedRules', 'Facebook', 'LeadEnforce']
    }
  }
})
